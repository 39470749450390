@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;0,1000;1,700&display=swap');

body {
    font-family: 'Mulish', sans-serif;
    color: #231430;
}

p {
    color: #363636;
}
.text-white p {
    color: #8e9095;
}

.section-gap {
    padding-top: 120px;
    padding-bottom: 120px;
    @media screen and (max-width: 991px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    &-lg {
        padding-top: 180px;
        padding-bottom: 180px;
    }
    &-md {
        padding-top: 75px;
        padding-bottom: 75px;
    }
    &-sm {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &-bottom {
        padding-bottom: 100px;
    }
    &-top {
        padding-top: 100px;
    }
}


.section-bg-blur {
    position: relative;
    z-index: 1;
    &:before {
        background-color: #d41c25;
        content: "";
        -webkit-filter: blur(415px);
        filter: blur(415px);
        height: 300px;
        left: 50%;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 583px;
        z-index: -1;
    }
}

.section-platform {
    z-index: 0;
    position: relative;
    &:before {
        --width: 126px;

        content: "";
        position: absolute;
        left: 300px;
        top: 150px;
        height: var(--width);
        width: var(--width);
        background-color: #d41c25;
        border-radius: 100%;
        filter: blur(150px);
        -webkit-filter: blur(150px);
        z-index: -1;
    }
}

.theme-single-image{
    margin-top: -352px;
    z-index: 1;
    position: relative;
    @media screen and (max-width: 1399px) {
        margin-top: -200px;
    }
    @media screen and (max-width: 1199px) {
        margin-top: -100px;
    }
}

.list-style-star {
    @extend .list-unstyled;
    @extend .d-flex;
    @extend .flex-column;
    @extend .gap-3;
    li {
        @extend .d-flex;
        @extend .align-items-start;
        @extend .gap-4;
        font-size: 18px;
        img {
            margin-top: 8px;
        }
    }
}

.theme-case-study {
    background-color: #7EB6FF;
    padding: 44px 74px;
    border-radius: 25px;
    @media screen and (max-width: 1199px) {
        padding: 60px 30px;
    }
    @media screen and (max-width: 767px) {
        padding: 30px 30px;
    }
}

.mix-hue {
    mix-blend-mode: hue;
}

.bg-half-black {
    position: relative;
    z-index: 0;
    &:after {
        content: "";
        position: absolute;
        top: 130px;
        left: 0;
        height: calc(100% - 129px);
        width: 100%;
        background-color: #070A1B;
        z-index: -1;
    }
}

.slick-arrow {
    display: none !important;
}

.scroll-sticky-count {
    padding: 10px 14px;
    border-radius: 5px;
    background-color: rgba(#D41C25, .5);
    .count {
        display: flex;
        align-items: center;
        opacity: .75;
        &.active {
            font-size: 20px;
            font-weight: 700;
            opacity: 1;
        }
        .line {
            display: block;
            height: 3px;
            width: 100px;
            border-radius: 6px;
            background-color: #fff;
            margin-left: 12px;
        }
    }
}

.testi-slide {
    .client-quote {
        font-size: 24px;
        text-align: center;
        font-style: italic;
        opacity: .75;
        margin-bottom: 40px;
        @media screen and (max-width: 991px) {
            font-size: 18px;
        }
    }
    .testi-img {
        max-height: 100px;
    }
}

.animate-spin {
    animation: spin 20s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.brand-badge {
    @media screen and (max-width: 1199px) {
        width: 100px;
        height: 100px;
    }
    @media screen and (max-width: 767px) {
        width: 60px;
        height: 60px;
    }
}

.cal-height {
    height: calc(100vh - 110px);
}