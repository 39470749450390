.card-digital {
    padding: 35px;
    border-radius: 10px;
    background-color: #111424;
    border: 1px solid #1E2339;
    color: white;
    .card-title {
        font-weight: 700;
        font-size: 31px;
        margin-bottom: 16px;
    }
    .card-desc {
        color: #8E9095;
        font-size: 20px;
    }
    .card-link {
        margin-top: 40px;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        color: #00D1EE;
    }
    .icon-square {
        background-color: #222535;
        margin: 8px;
        margin-bottom: 40px;
        position: relative;
        z-index: 1;
        &:after {
            content: "";
            position: absolute;
            top: -8px;
            left: -8px;
            height: calc(100% + 16px);
            width: calc(100% + 16px);
            border-radius: inherit;
            background-color: inherit;
            opacity: .5;
            z-index: -1;
        }
    }
    &:nth-child(1) {
        .icon-square {
            background-color: #01DAE9;
        }
    }
    &:nth-child(2) {
        .icon-square {
            background-color: #1D6AF2;
        }
    }
    &:nth-child(3) {
        .icon-square {
            background-color: #AF31FC;
        }
    }
    &:nth-child(4) {
        .icon-square {
            background-color: #FF46EC;
        }
    }
}

.card-platform {
    padding: 30px;
    border-radius: 10px;
    background-color: #111424;
    border: 1px solid #1E2339;
    border-top-width: 0px;
    border-left-width: 0px;
    color: white;
    height: 100%;
    &:hover {
        border-color: rgba(#D41C25, .5);
    }
    .card-title {
        font-weight: 700;
        font-size: 31px;
        margin-bottom: 16px;
    }
    .card-desc {
        color: #8E9095;
        font-size: 20px;
    }
    .card-link {
        margin-top: 40px;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        color: #00D1EE;
    }
    .icon-square {
        background-color: #222535;
        margin: 8px;
        margin-bottom: 40px;
        position: relative;
        z-index: 1;
        &:after {
            content: "";
            position: absolute;
            top: -8px;
            left: -8px;
            height: calc(100% + 16px);
            width: calc(100% + 16px);
            border-radius: inherit;
            background-color: inherit;
            opacity: .5;
            z-index: -1;
        }
    }
}