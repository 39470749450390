.theme-footer {
    padding-top: 70px;
}
.footer {
    &-top {
        margin-bottom: 10px;
    }
    &-content {
        p {
            color: #FCFCFC;
        }
    }
    &-bottom {
        border-top: 1px solid rgba(#5E6062, .2);
        padding-top: 40px;
        padding-bottom: 40px;
    }
    &-copyright {
        color: #8E9095;
        font-size: 14px;
        @extend .d-flex;
        @extend .align-items-center;
    }
    &-widget {
        margin-bottom: 40px;
        .widget-title {
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: 1px;
            color: #FCFCFC;
        }
        ul {
            @extend .list-unstyled;
            @extend .d-flex;
            @extend .flex-column;
            @extend .gap-2;
            li a {
                color: #8E9095;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}

.social-links {
    display: flex;
    gap: 12px;
    a {
        width: 36px;
        height: 32px;
        font-size: 16px;
        border-radius: 5px;
        background-color: #111424;
        border: 1px solid #1E2339;
        color: #D41C25;
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
        &:hover {
            @extend .bg-primary;
            color: #fff;
        }
    }
}