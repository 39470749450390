.theme-hero {
    background-color: #070A1B;
    padding-top: 300px;
    padding-bottom: 100px;
    @media screen and (max-width: 1199px) {
        padding-top: 160px;
        padding-bottom: 100px;
    }
    &.image-layer {
        padding-top: 252px;
        padding-bottom: 452px;
        @media screen and (max-width: 1399px) {
            padding-top: 100px;
            padding-bottom: 240px;
        }
        @media screen and (max-width: 1199px) {
            padding-bottom: 140px;
        }
    }
    &.bc-area {
        padding-bottom: 100px;
        .object-1 {
            left: 145px;
        }
        .object-2 {
            right: 120px;
        }
        .section-title {
            .main-title {
                font-size: 75px;
                @media screen and (max-width: 1199px) {
                    font-size: 56px;                
                }
            }
        }
    }
    .section-title {
        .main-title {
            font-size: 100px;
            @media screen and (max-width: 1199px) {
                font-size: 62px;                
            }
            @media screen and (max-width: 767px) {
                font-size: 36px;
            }
        }
        .sub-title {
            font-size: 23px;
            @media screen and (max-width: 767px) {
                font-size: 18px !important;
            }
        }
    }
}

.bg-vector {
    background-image: url('../../img/hero-vector-bg.svg');
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
}

.text-bg-vector {
    display: inline-block;
    position: relative;
    &:after {
        content: "";
        background-image: url('../../img/text-bg-vector.svg');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% + 20px);
        left: -80px;
        height: 100%;
        width: calc(100% + 200px);
        z-index: -2;
        @media screen and (max-width: 1199px) {
            width: calc(100% + 100px);
            top: 50%;
            left: -40px;
        }
    }
}

// Brands
.theme-brands-hero {
    width: 100%;
    margin-top: 50px;
    height: 180px;
    background-color: #fff;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -30px;
        height: 100%;
        width: calc(100% + 60px);
        transform: rotate(1.85deg);
        z-index: -1;
        @extend .bg-primary;
    }
}

.brands-wrapper {
    position: relative;
    z-index: 1;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100px;
        background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
        z-index: 3;
    }
    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100px;
        background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
        z-index: 3;
    }
}