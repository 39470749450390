.icon-square {
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.icon-square-lg {
        height: 148px;
        width: 148px;
    }
    &.icon-square-md {
        height: 65px;
        width: 65px;
    }
    &.icon-square-sm {
        height: 32px;
        width: 32px;
    }
    &.icon-square-xs {
        height: 24px;
        width: 24px;
    }

    &.icon-circle {
        border-radius: 999px;
    }
}