.theme-brands {
    background-color: #051313;
    @extend .text-white;
}

.brand-logo {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;

    img {
        max-width: 170px;
    }
}

.brand-img {
    max-width: 120px;
}

.brands-wrapper .slick-track {
    display: flex;
    align-items: center;
}