.section-title {
    margin-bottom: 80px;
    .pre-title {
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #D41C25;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 20px;
        padding: 4px 10px;
        border-radius: 5px;
        background-color: #F8F4F1;
        display: inline-block;
    }
    .main-title {
        color: #101011;
        font-weight: 900;
        font-size: 48px;
        line-height: 130%;
        margin-bottom: 30px;
        @media screen and (max-width: 1199px) {
            font-size: 36px;
        }
        @media screen and (max-width: 767px) {
            font-size: 28px;
        }
    }
    .sub-title {
        color: #363636;
        font-weight: 500;
        font-size: 20px;
        line-height: 170%;
        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }

    &.text-white {
        .pre-title {
            background-color: #111424;
        }
        .main-title {
            @extend .text-white;
        }
        .sub-title {
            color: #8E9095;
        }
    }

    &.text-light {
        .main-title {
            font-weight: 700;
        }
    }

    &.mb-md {
        margin-bottom: 50px;
    }
}