// Navbar
@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}
body {
    --bs-border-width: 2px;
    overflow-x: hidden;
}

.navbar {
    --bs-navbar-padding-y: 25px;
    --bs-navbar-padding-x: 0px;
    --bs-navbar-toggler-focus-width: 0;
    --bs-navbar-nav-link-padding-x: 22px;
    --bs-navbar-nav-link-padding-y: 6px;
    transition: .25s linear;
    .navbar-brand {
        max-width: 260px;
        @media screen and (max-width: 575px) {
            max-width: 220px;
        }
        img {
            transition: .25s linear;
            width: 260px;
        }
    }
    @media screen and (max-width: 575px) {
        --bs-navbar-padding-x: 0px;
        .navbar-brand {
            max-width: 180px;
        }
    }
    @media screen and (max-width: 1199px) {
        .navbar-collapse {
            margin-top: 20px;
            padding: 20px;
            background-color: #111424;
        }
    }
}
.navbar-nav {
    transition: .25s linear;
    a {
        border-radius: 999px;
        display: inline-flex;
        &:hover {
            background-color: #282A37;
        }
    }
    @media screen and (max-width: 1399px) {
        gap: 16px;
    }
    @media screen and (min-width: 1200px) {
        background-color: #111424;
        border: 1px solid #1E2339;
        padding: 20px 65px;
        border-radius: 999px;
    }
    @media screen and (max-width: 1199px) {
        a {
            border-radius: 5px;
            padding: 10px;
        }
    }

}

.navbar.sticky-navbar {
    --bs-navbar-padding-y: 15px;
    .navbar-nav {
        @media screen and (min-width: 1200px) {
            padding: 10px 65px;
        }
    }
    .navbar-brand {
        width: 260px;
        img {
            max-width: 220px;
            @media screen and (max-width: 575px) {
                max-width: 140px;
            }
        }
    } 
}

// Button
.btn {
    font-weight: 500;
    &-blank {
        &:hover {
            background-color: rgba(0,0,0,.1);
        }
        &:focus, &:active {
            border: none !important
        }
    }
    @media screen and (max-width: 992px){
        --bs-btn-padding-x: 24px;
        --bs-btn-padding-y: 12px;
    }
}

// Dropdown Toggle
.dropdown-toggle::after {
    content: "\f078";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    border: none;
    margin-left: 6px;
    font-size: 12px;
    vertical-align: middle;
}

// Font Weight
.fw-extrabold {
    font-weight: 800;
}