.theme-cta {
    background-color: #D41C25;
    color: #fff;
    padding: 80px 80px 80px 80px;
    border-radius: 20px;
    background-image: url('../../img/cta-bg.png');
    background-size: cover;
    background-position: center center;
    @media screen and (max-width: 767px) {
        padding: 80px 50px;
    }
    @media screen and (max-width: 575px) {
        padding: 60px 30px;
    }
    p {
        color: #fff;
    }
}